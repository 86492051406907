<template>
  <window-content>
    <div class="wrapper-sm">
      <e-row>
        <e-col style="min-width: 90%">
          <erp-s-field
              view="tl"
              label="Informe o mês para gerar o relatório"
          >
            <erp-select size="1" placeholder="Selecione o mês" :options="meses" v-model="filters.mes" />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)"/>
        <u-btn @click="gerar(formato)" :loading="loading" label="Gerar" icon="print" icon-type="fa" icon-style="light" flat no-caps/>
      </div>
    </window-footer>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowFooter,
  ErpSField,
  ErpInput,
  ErpSelect
} from 'uloc-vue-plugin-erp'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import mixinPrint from "@/components/relatorios/components/mixinPrint"
import {relatorio} from "@/domain/relatorios/services"
import createPrint from "@/components/relatorios/components/customPrint/createPrint"

let filters = {
  mes: null,
}

export default {
  name: 'AniversariantesMesWindow',
  mixins: [mixinPrint],
  data() {
    let date = new Date()
    filters = JSON.parse(JSON.stringify(filters))
    filters.mes = date.getMonth() + 1
    return {
      formato: 'html',
      filters: filters,
      meses: [
        {label: 'Janeiro', value: 1},
        {label: 'Fevereiro', value: 2},
        {label: 'Março', value: 3},
        {label: 'Abril', value: 4},
        {label: 'Maio', value: 5},
        {label: 'Junho', value: 6},
        {label: 'Julho', value: 7},
        {label: 'Agosto', value: 8},
        {label: 'Setembro', value: 9},
        {label: 'Outubro', value: 10},
        {label: 'Novembro', value: 11},
        {label: 'Dezembro', value: 12},
      ]
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    createPrint: createPrint,
    actionPrint (action) {
      this.gerar(action)
    },
    gerar(formato = 'html') {
      let filters = JSON.parse(JSON.stringify(this.filters))

      this.loading = true
      relatorio('aniversariantesMes', filters, formato)
          .then(response => {
            this.loading = false
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'gerencial', 'Aniversariantes do Mês', 'landscape')
          })
          .catch(error => {
            this.loading = false
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    }
  },
  components: {
    ECol,
    ERow,
    WindowFooter,
    WindowContent,
    ErpSField,
    // ErpInput,
    ErpSelect
  }
}
</script>
